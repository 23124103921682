<template>
  <div class="in-workbench">

    <el-row :gutter=20>
      <el-col :span=4>
<!--        <el-button type="primary" class="btn-full" >机组实时数据</el-button>-->
      </el-col>
    </el-row>
    <!-- 顶部操作栏结束，表格开始 -->
    <el-row :gutter=20>
      <el-col :span=24>
        <el-table :data="allMachine" border class="tableInWorkbench" height="calc(100vh - 180px)" v-loading="loading">
          <el-table-column  label="基本信息">        //此处给机组一个最低宽度
            <el-table-column  prop="stationName" width="200" label="机组"></el-table-column>
            <el-table-column prop="supplyArea" label="实供面积"></el-table-column>
          </el-table-column>
          <el-table-column label="一级网参数">
            <el-table-column prop="firstSuPressure" label="供压"></el-table-column>
            <el-table-column prop="firstRePressure" label="回压"></el-table-column>
            <el-table-column prop="firstSuTempature" label="供温"></el-table-column>
            <el-table-column prop="firstReTempature" label="回温"></el-table-column>
<!--            <el-table-column prop="company" label="温差"></el-table-column>-->
            <el-table-column prop="firstFlow" label="流量"></el-table-column>
          </el-table-column>
          <el-table-column label="二级网参数">
<!--            <el-table-column prop="targetTempature" label="温度目标"></el-table-column>-->
            <el-table-column prop="secSuPressure" label="供压"></el-table-column>
            <el-table-column prop="secRePressure" label="回压"></el-table-column>
            <el-table-column prop="secSuTempature" label="供温"></el-table-column>
            <el-table-column prop="secReTempature" label="回温"></el-table-column>
            <el-table-column prop="waterTankLevel" label="液位"></el-table-column>         //二网流量
            <!--            <el-table-column prop="" label="温差"></el-table-column>-->
<!--            <el-table-column prop="" label="平均温度"></el-table-column>-->
          </el-table-column>
          <el-table-column label="主调节阀">
            <el-table-column prop="targetTempature" label="设定"></el-table-column>
            <el-table-column prop="mainValveFeed" label="反馈"></el-table-column>
          </el-table-column>
          <el-table-column label="辅调节阀">
            <el-table-column prop="auxValveSet" label="给定"></el-table-column>
            <el-table-column prop="auxValveFeed" label="反馈"></el-table-column>
          </el-table-column>
          <el-table-column label="循环泵">
            <el-table-column prop="circulatingPumpSet" label="给定"></el-table-column>
            <el-table-column prop="circulatingPumpCode" label="反馈1"></el-table-column>
            <el-table-column prop="circulatingPumpCode" label="反馈2"></el-table-column>
          </el-table-column>

          <el-table-column  label="室外气温">
            <el-table-column prop="roomTempatureAvg" label="°C"></el-table-column>
          </el-table-column>
          <el-table-column width="170" prop="createDate" label="更新时间"></el-table-column>

<!--          <el-table-column label="热量参数">-->
<!--            <el-table-column prop="electricPower" label="瞬时电量"></el-table-column>-->
<!--            <el-table-column prop="heatConsumption" label="热负荷"></el-table-column>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="前一日热数据">-->
<!--            <el-table-column prop="company" label="用热量"></el-table-column>-->
<!--            <el-table-column prop="company" label="平均热负荷"></el-table-column>-->
<!--            <el-table-column prop="company" label="年度供热量"></el-table-column>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="电量参数">-->
<!--            <el-table-column prop="company" label="实时功率"></el-table-column>-->
<!--            <el-table-column prop="company" label="前日用电量"></el-table-column>-->
<!--            <el-table-column prop="electricTotal" label="年度用电"></el-table-column>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="补水参数">-->
<!--            <el-table-column prop="company" label="日补水"></el-table-column>-->
<!--            <el-table-column prop="company" label="年度补水量"></el-table-column>-->
<!--          </el-table-column>-->

          <el-table-column :resizable="false" align="center" :width=100 label="操作">
            <template slot-scope="scope">
              <el-link @click="lookLine(scope.row)">查看曲线</el-link>&nbsp;
<!--              <el-link @click="deleteMachine(scope.row)">删除</el-link>-->
            </template>
          </el-table-column>



        </el-table>
      </el-col>
    </el-row>
    <div class="block">
      <span class="demonstration"></span>
      <el-pagination
        @size-change="handleSizeChange"
        :current-page.sync="pages" @current-change="handleCurrentChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="pagetotal">
      </el-pagination>
    </div>
    <template>
    <el-dialog title="选择机组历史数据" :visible.sync="managementinfo" :append-to-body="true" width="30%"
               top="calc((100vh - 600px) / 2)" :center="true" :close-on-click-modal=false>
      <el-form>
        <el-row style="text-align: center">
          <el-col :xs=24 :sm=12>
            <el-form-item label="选择机组" label-width="80px">
              <template>
                <el-select v-model="addmanagementinfo.name" filterable placeholder="请选择">
                  <el-option
                    v-for="item in option"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureadd">确定新增</el-button>
        <el-button type="danger" @click="back">取消新增</el-button>
      </span>
    </el-dialog>
</template>
  </div>
</template>

<script>
  import permissions from "../../js/permissions";
  import clientUtils from "../../js/clientUtils";

  export default {
    name: 'RealTime',
    data: function () {
      return {
        allMachine:[],
        option: [],
        pagetotal: null,
        pages: 0,
        fillpage: 1,
        managementinfo: false,
        addmanagementinfo:{},
        pagePermissionKey: permissions.NONE,
        loading: true
      }
    },
    methods: {
      sureadd:function () {
        this.managementinfo =false
        console.log(this.addmanagementinfo.name)
      },
      newMachine:function () {
        this.managementinfo =true
      },
      back:function () {
        this.managementinfo =false
      },

      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      lookLine(val){
        this.$router.push({name:'line',params:{stationId:val.stationId}})
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$emit('selectMenu', this.$route.path, '热网实时数据')
      })
      const that = this
      clientUtils.get("/system/networkData/selectByRealTime",function (res) {
        if(res.data!=null){
          that.allMachine = res.data.data
          that.loading = false
          console.log(that.allMachine)
        }
      })
    }
  }
</script>

<style scoped>

</style>
